import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Disclaimer" keywords={['']}  pathname={this.props.location.pathname}/>
        <PageHeader>
          <h1>Disclaimer</h1>
        </PageHeader>
        <div className="page childPadding">
          <p>
            Firstversionist B.V. (Registered at KvK 74263404), ("us", "we", "our" or "Firstversionist"), grants you
            access to https://polypane.app, ("the Website"). We retains the rights to change or remove contents at any
            point without notice.
          </p>

          <h3>Limited liability</h3>
          <p>
            We makes every effort to keep the contents on the Website up to date and/or supplement the content as often
            as possible. Despite this care and attention it is possible that content is incomplete and/or incorrect.
          </p>

          <p>
            The materials of the Website are offered without any form of guarantee or claim to correctness. These
            materials may change at any time without prior notice from us. In particular, all prices on the Website are
            subject to typing and programming errors. No liability is accepted for the consequences of such errors. No
            agreement is concluded based on such errors.
          </p>

          <p>
            The Website may contain links to other sites that are not operated by us. If you click on a third party
            link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy
            of every site you visit. We have no control over and assume no responsibility for the content, privacy
            policies or practices of any third party sites or services. Site owners and content may change without
            notice and may occur before Firstversionist has the opportunity to remove any offending links.
          </p>

          <h3>Copyright</h3>
          <p>
            All intellectual property rights concerning the content of the Website belong to Firstversionist. Copying,
            distribution and other use of these materials is not permitted without written permission from
            Firstversionist, except and only to the extend otherwise provided in regulations of mandatory law, unless
            specifically stated otherwise.
          </p>

          <h3>Consent</h3>

          <p>By using our website, you hereby consent to this disclaimer and agree to its terms.</p>

          <h3>Update</h3>

          <p>This disclaimer may change from time to time.</p>

          <p>
            If you require any more information or have any questions about Firstversionists disclaimer, please contact
            us by email at contact@firstversionist.com
          </p>
        </div>
      </Layout>
    );
  }
}

export default Page;
